/* CSS Styling Guide */
/* https://piccalil.li/blog/a-modern-css-reset/  */

/* Libre Caslon */
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap');

/* PP Mori Extra Light */
@font-face {
  font-family: 'PPMori';
  font-weight: 200;
  src: url('./assets/fonts/PPMori-Extralight.otf') format('opentype');
}

/* PP Mori Regular */
@font-face {
  font-family: 'PPMori';
  font-weight: 400;
  src: url('./assets/fonts/PPMori-Regular.otf') format('opentype');
}

/* PP Mori Semi Bold */
@font-face {
  font-family: 'PPMori';
  font-weight: 600;
  src: url('./assets/fonts/PPMori-SemiBold.otf') format('opentype');
}

/* PP Mori Extra Light Italic */
@font-face {
  font-family: 'PPMori';
  font-weight: 200;
  font-style: italic;
  src: url('./assets/fonts/PPMori-ExtralightItalic.otf') format('opentype');
}

/* PP Mori Regular Italic */
@font-face {
  font-family: 'PPMori';
  font-weight: 400;
  font-style: italic;
  src: url('./assets/fonts/PPMori-RegularItalic.otf') format('opentype');
}

/* PP Mori Semi Bold Italic */
@font-face {
  font-family: 'PPMori';
  font-weight: 600;
  font-style: italic;
  src: url('./assets/fonts/PPMori-SemiBoldItalic.otf') format('opentype');
}

// --------------------------------------------------------------------------------

/* PP Right Didone Light */
@font-face {
  font-family: 'PPRightDidone';
  font-weight: 200;
  src: url('./assets/fonts/PPRightDidone-Light.otf') format('opentype');
}

/* PP Right Didone Medium */
@font-face {
  font-family: 'PPRightDidone';
  font-weight: 400;
  src: url('./assets/fonts/PPRightDidone-Medium.otf') format('opentype');
}

:root {
  /* Theme colors */
  --clr-accent-1000: #eef0c6;
  --clr-accent-900: #36019b;
  --clr-accent-800: #e1ffd7;
  --clr-accent-700: #c9fe64;
  --clr-accent-650: #8fce00;
  --clr-accent-600: #136207;
  --clr-accent-500: #fec453;
  --clr-accent-400: #dab24c;
  --clr-accent-300: #df9a11;
  --clr-accent-200: #996700;
  --clr-accent-100: #b7ab98;

  --clr-neutral-900: #0c0c0e;
  --clr-neutral-800: #0d0d0d;
  --clr-neutral-750: #0f0f0f;
  --clr-neutral-700: #161616;
  --clr-neutral-400: #131313;
  --clr-neutral-300: #5f5f5f;
  --clr-neutral-200: #777777;
  --clr-neutral-180: #cecece;
  --clr-neutral-150: white;
  --clr-neutral-100: hsl(0, 0%, 100%);

  /* Default font */
  --ff-primary: 'PPMori', sans-serif;
  // --ff-secondary: 'PPRightDidone', sans-serif;
  --ff-secondary: 'Libre Caslon Text', serif;

  --ff-body: var(--ff-primary);
  --ff-heading: var(--ff-primary);

  /* Font weights */
  --fw-light: 200;
  --fw-regular: 400;
  --fw-semi-bold: 600;
  --fw-bold: 700;

  /* Font sizes */
  --fs-200: 0.6rem;
  --fs-300: 0.8125rem;
  --fs-350: 0.835rem;
  --fs-400: 0.875rem;
  --fs-500: 0.9375rem;
  --fs-550: 0.98rem;
  --fs-600: 1rem;
  --fs-620: 1.1rem;
  --fs-650: 1.2rem;
  --fs-660: 1.3rem;
  --fs-670: 1.4rem;
  --fs-680: 1.5rem;
  --fs-700: 1.875rem;
  --fs-750: 1.9rem;
  --fs-800: 2.5rem;
  --fs-900: 3.5rem;
  --fs-1000: 4rem;
  --fs-1100: 5rem;
  --fs-1500: 9rem;

  --fs-body: var(--fs-400);
  --fs-primary-heading: var(--fs-800);
  --fs-secondary-heading: var(--fs-700);
  --fs-nav: var(--fs-500);
  --fs-button: var(--fs-300);

  /* Spacing */
  --size-100: 0.25rem;
  --size-200: 0.5rem;
  --size-300: 0.75rem;
  --size-400: 1rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-650: 2.5rem;
  --size-700: 3rem;
  --size-800: 4rem;
  --size-900: 5rem;
  --size-950: 5.5rem;
  --size-1000: 7rem;
  --size-1050: 8rem;
  --size-2000: 10rem;

  /* Resolutions */
  --media-desktop: 2080px;
  --media-laptop: 1680px;
  --media-tablet: 1040px;
  --media-mobile: 696px;

  /* Screen sizes */
  --screen-xxs: 320px;
  --screen-xs: 576px;
  --screen-sm: 768px;
  --screen-md: 992px;
  --screen-lg: 1200px;
  --screen-xl: 1400px;
  --screen-xxl: 2000px;
  --screen-xxxl: 3000px;

  /* Box Shadows */
  --box-shadow-200: rgba(0, 0, 0, 0.103) 0px 7px 29px 0px;
  --box-shadow-300: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
  --box-shadow-400: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  /* Border Radius */
  --border-radius-100: 5px;
  --border-radius-150: 8px;
  --border-radius-200: 10px;
  --border-radius-300: 15px;
  --border-radius-400: 20px;
  --border-radius-500: 25px;
  --border-radius-600: 30px;
  --border-radius-700: 40px;
  --border-radius-800: 50px;
  --border-radius-900: 60px;

  /* Home circles */
  --circle-diameter: 25vw;
  --num-circles: 5;

  /* Screen sizes */
  --screen-xxs: 320px;
  --screen-xs: 576px;
  --screen-sm: 768px;
  --screen-md: 992px;
  --screen-lg: 1200px;
  --screen-xl: 1400px;
  --screen-xxl: 2000px;
  --screen-xxxl: 3000px;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Set core root defaults */
html:focus-within {
  /* scroll-behavior: smooth; */
}

html,
body {
  // max-width: 100vw;
  // overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Set core body defaults */
body {
  // min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.3;
  /* overflow: hidden; */

  font-size: var(--fs-body);
  font-family: var(--ff-body);
  background-color: var(--clr-neutral-150);
  color: var(--clr-neutral-900);
  position: relative;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 768px) {
    max-width: 2560px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.mainContent {
  min-height: calc(100vh - 12.875rem);
  position: relative;
  z-index: 1;
  background-color: var(--clr-neutral-150);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0.0625rem 0.1875rem,
    rgba(0, 0, 0, 0.08) 0px 0.625rem 0.9375rem -0.3125rem,
    rgba(0, 0, 0, 0.08) 0px 0.4375rem 0.4375rem -0.3125rem;
  // padding-bottom: 2rem;
}

.mobileSmDisplayNone {
  @media (max-width: 576px) {
    display: none !important;
  }
}

.desktopSmDisplayNone {
  @media (min-width: 577px) {
    display: none !important;
  }
}

// Lenis scroll styles
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
