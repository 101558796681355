.about-container {
  z-index: 20;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: -15px;
  left: 0;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-backdrop-filter: blur(300px);
  backdrop-filter: blur(300px);
  background-color: rgba(0, 0, 0, 0.3);
  scroll-behavior: smooth;
}

.about-wrapper {
  position: relative;
  width: 100%;
}

.about-nav {
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--size-300);
  padding-inline: 1rem;
  position: sticky;
  top: 16px;
  mix-blend-mode: difference;
  color: var(--clr-neutral-100);
  z-index: 6;

  @media (min-width: 768px) {
    padding-inline: 0;
    margin-top: var(--size-700);
  }

  .about-logo {
    cursor: pointer;
    min-height: 60px;

    img {
      width: 150px;
      height: auto;

      @media (min-width: 768px) {
        width: 190px;
      }
    }
  }

  button {
    width: 90px;
    height: 35px;
    border: 1px solid var(--clr-neutral-150);
    background: transparent;
    font-family: var(--ff-primary);
    font-size: var(--fs-400);
    font-weight: var(--fw-regular);
    color: var(--clr-neutral-150);
    cursor: pointer;
    text-align: center;
    margin-top: -8px;

    @media (min-width: 768px) {
      width: 100px;
      height: 40px;
      font-size: var(--fs-600);
      margin-top: 0;
    }
  }
}

.body-lock {
  position: fixed; /* Keep the position */
  overflow-y: scroll; /* Enable scrollable content */
  width: 100%; /* Prevent width reflow */
}

.textReveal-container {
  width: 81%;
  margin: 8rem 0 0 1rem;
  position: relative;
  z-index: 3;

  @media (min-width: 768px) {
    margin: 13rem 0 0rem 0;
  }

  section {
    display: flex;
    align-items: center; // Center the text vertically
    justify-content: center; // Center the text horizontally

    .reveal-type {
      font-size: clamp(2rem, 5vw, 4.5rem);
      line-height: 1.2;
      word-break: normal;
      //   word-spacing: 0.15em;

      @media (min-width: 768px) {
        font-size: clamp(2rem, 5vw, 4.05rem);
      }
    }
  }
}

.about-profile {
  position: absolute;
  top: 28rem;
  right: 5rem;

  @media (min-width: 768px) {
  }

  img {
    width: 300px;
    height: 400px;
    object-fit: cover;
    object-position: 0 10%;
    // border-radius: 1.5rem;

    @media (min-width: 768px) {
      width: 480px;
      height: 600px;
    }
  }
}

.about-profile-mobile {
  width: 100%;
  padding-inline: 1rem;
  margin-top: var(--size-600);
  display: flex;
  justify-content: center;

  img {
    width: 300px;
    height: 400px;
    object-fit: cover;
    object-position: 0 10%;
    // border-radius: 1.5rem;

    @media (min-width: 768px) {
      width: 480px;
      height: 600px;
    }
  }
}

.about-description {
  width: 100%;
  grid-template-columns: 1fr;
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 1rem;
  padding-bottom: 7.5rem;
  color: var(--clr-neutral-100);
  margin-top: var(--size-700);

  @media (min-width: 768px) {
    margin-top: var(--size-1000);
    flex-direction: row;
    padding-inline: 0;
  }

  .about-member {
    width: 100%;
    grid-row-gap: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    display: flex;

    .h-line {
      width: 100%;
      height: 1px;
      background-color: var(--clr-neutral-100);
    }

    h3 {
      font-size: 1rem;
      font-weight: var(--fw-semi-bold);
      line-height: 1.2375;

      @media (min-width: 768px) {
        font-size: 1.125rem;
      }
    }

    .about-member-list {
      display: flex;
      flex-direction: column;
      gap: var(--size-300);
      margin-top: var(--size-200);

      p {
        line-height: 1.3;
        font-size: 1.5rem;
        font-weight: var(--fw-regular);

        @media (min-width: 768px) {
          font-size: 2rem;
        }
      }
    }
  }

  .about-focus {
    width: 100%;
    grid-row-gap: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    display: flex;

    .h-line {
      width: 100%;
      height: 1px;
      background-color: var(--clr-neutral-100);
    }

    h3 {
      font-size: 1rem;
      font-weight: var(--fw-semi-bold);
      line-height: 1.2375;

      @media (min-width: 768px) {
        font-size: 1.125rem;
      }
    }

    .about-focus-list {
      display: flex;
      flex-direction: column;
      gap: var(--size-300);
      margin-top: var(--size-200);

      p {
        line-height: 1.3;
        font-size: 1.5rem;
        font-weight: var(--fw-regular);

        @media (min-width: 768px) {
          font-size: 2rem;
        }
      }
    }
  }
}

.about-footer {
  margin-top: 15rem;
  padding-inline: 1rem;
  padding-bottom: var(--size-600);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;

  @media (min-width: 768px) {
    margin-top: 25rem;
    padding-inline: 0;
    padding-bottom: var(--size-700);
  }

  .about-footer-left {
    .about-footer-logo {
      img {
        width: 350px;

        @media (min-width: 768px) {
          width: 600px;
        }
      }
    }
  }

  .about-footer-right {
    .about-footer-right-email {
      width: fit-content;
      padding: 10px;
      border: 1px solid var(--clr-neutral-100);
      background: transparent;

      h3 {
        font-size: var(--fs-600);
        font-weight: var(--fw-semi-bold);
        color: var(--clr-neutral-100);
      }
    }
  }
}
