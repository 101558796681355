.bottomContent-top {
  background-color: var(--clr-accent-500);
  height: 100vh;
  width: 100%;
  margin-top: -90vh;
  // margin-top: -70vh;
  z-index: 2;

  @media (min-width: 768px) {
  }
}

.bottomContent-spacer {
  //   height: 8.5rem;
}

.bottomContent {
  background-color: var(--clr-accent-500);
  position: sticky;
  bottom: 0px;
  height: fit-content;
  padding-top: 5rem;
  padding-inline: 0;
  padding-bottom: 200px;
  width: 100%;
  z-index: 1;

  @media (min-width: 768px) {
    padding-inline: 100px;
  }

  &.onTop {
    z-index: 2;
  }

  .bottomContent-content-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // padding-top: var(--size-900);

    .bottomContent-content-top-heading {
      h2 {
        font-size: var(--fs-900);
        font-weight: var(--fw-bold);
        line-height: 1.1;
        color: transparent;
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: var(--clr-neutral-900);

        @media (min-width: 768px) {
          font-size: var(--fs-1100);
        }

        span {
          -webkit-text-stroke-width: 0px;
          -webkit-text-stroke-color: var(--clr-neutral-900);
          color: var(--clr-neutral-900);
        }
      }
    }

    .bottomContent-content-top-button {
      margin-top: var(--size-600);

      button {
        font-family: var(--ff-primary);
        font-size: var(--fs-600);
        font-weight: var(--fw-semi-bold);
        width: 250px;
        height: 70px;
        padding: 10px;
        border-radius: 100vw;
        border: 2px solid var(--clr-neutral-100);
        background: var(--clr-neutral-900);
        color: var(--clr-neutral-100);
        cursor: pointer;
      }
    }
  }
}

.bottomContent-content-bottom {
  background: var(--clr-accent-500);
  display: flex;
  flex-direction: column;
  gap: var(--size-400);
  z-index: 3;
  width: 100%;
  padding-bottom: 1.5rem;
  padding-inline: 1rem;

  @media (min-width: 768px) {
    padding-bottom: 2.5rem;
    padding-inline: 0;
  }

  .bottomContent-content-bottom-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;

    .bottomContent-content-bottom-left {
      .bottomContent-content-bottom-logo {
        img {
          width: 400px;
          height: auto;
          filter: invert(100%);
          mix-blend-mode: multiply;
        }
      }

      .bottomContent-content-bottom-copyright {
        margin-top: var(--size-400);

        h3 {
        }
      }
    }

    .bottomContent-content-bottom-right {
      .bottomContent-content-bottom-socials {
        .bottomContent-content-bottom-socials-website {
          width: fit-content;
          padding: 10px;
          border: 1px solid var(--clr-neutral-900);
          background: transparent;

          h3 {
            font-size: var(--fs-600);
            font-weight: var(--fw-semi-bold);
          }
        }
      }
    }
  }
}
