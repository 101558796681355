.perspectiveText {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.75s cubic-bezier(0.76, 0, 0.24, 1);
  transform-style: preserve-3d;

  p {
    transition: all 0.75s cubic-bezier(0.76, 0, 0.24, 1);

    &:nth-of-type(2) {
      position: absolute;
      transform: rotateX(-90deg) translateY(9px);
      transform-origin: bottom center;
      opacity: 0;
    }
  }
}
