.home-container {
  position: relative;
  min-height: fit-content;
  background: var(--clr-neutral-700);
  z-index: 3;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .home-video {
    position: sticky;
    top: 0;
    min-height: 100vh;
    width: 100%;
    z-index: 2;

    video {
      position: absolute;
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      opacity: 1;
    }

    .video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      opacity: 0;
      transition: opacity 0.3s ease;
      pointer-events: none;
    }
  }

  .home-heading-wrapper {
    grid-row: 2;
    align-self: end;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 12rem;
    padding-left: var(--size-600);
  }

  .home-heading {
    font-size: var(--fs-800);
    font-weight: var(--fw-semi-bold);
    color: white;
    z-index: 2;
    max-width: 100%;
    width: 100%;
    padding: 0;
    box-sizing: border-box;

    @media (min-width: 768px) {
      font-size: var(--fs-1100);
      max-width: 2560px;
      padding: var(--size-400);
    }

    h1 {
      overflow: hidden; // Ensure the reveal effect is clipped
      span {
        display: inline-block; // Required for GSAP to animate individual words
        font-style: italic;
        font-family: var(--ff-secondary);
        font-weight: var(--fw-light);
        color: var(--clr-accent-500);
      }
    }

    span {
    }
  }
}

.secondSection-container {
  position: relative;
  min-height: fit-content;
  background: var(--clr-neutral-150);
  border-radius: 1rem;
  z-index: 3;
  // padding-top: 5.5rem;
  margin-bottom: 0;
  margin-inline: auto;

  .secondSection-wrapper {
    // display: flex;
    // flex-direction: row;
    padding-top: 3rem;

    // .second-section-home-image {
    //   img {
    //     width: 500px;
    //     height: auto;
    //     // border-radius: 20px;
    //     filter: url('#grain') grayscale(0.6) sepia(0.5) brightness(1.5);
    //     --b: 5px; /* the border thickness */
    //     --d: 20px; /* the distance from the edge */
    //     --o: 15px; /* the offset on hover */
    //     --s: 1; /* the direction of the hover effect (+1 or -1)*/

    //     --_d: calc(var(--d) + var(--s) * 1em);
    //     --_g: calc(100% - 2 * (var(--_d) + var(--b)));
    //     mask: conic-gradient(
    //           from 90deg at var(--_d) var(--_d),
    //           #0000 25%,
    //           #000 0
    //         )
    //         0 0 / calc(100% - var(--_d)) calc(100% - var(--_d)),
    //       linear-gradient(#000 0 0) 50% / var(--_g) var(--_g) no-repeat;
    //     font-size: 0;
    //     transition: 0.35s;

    //     &:hover {
    //       font-size: var(--o);
    //     }
    //   }
    // }

    .secondSection-top-bottom-wrapper {
      .secondSection-heading-top {
        color: black;
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        // padding-top: 5rem;

        .secondSection-heading-top-first {
          h1 {
            font-size: var(--fs-680);
            font-weight: var(--fw-regular);
          }
        }

        .secondSection-heading-top-left {
          h1 {
            font-size: 32px;
            font-weight: var(--fw-regular);
            font-family: var(--ff-secondary);
            font-style: italic;

            span {
              font-style: normal;
            }
          }
        }

        .secondSection-heading-top-right {
          p {
            font-size: 20px;
            font-weight: var(--fw-regular);
            max-width: 800px;
            line-height: 1.5;
          }
        }
      }

      .secondSection-heading-bottom {
        color: var(--clr-neutral-900);
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        margin-top: var(--size-1000);

        .secondSection-heading-bottom-first {
          h1 {
            font-size: var(--fs-680);
            font-weight: var(--fw-regular);
          }
        }

        .secondSection-heading-bottom-left {
          h1 {
            font-size: 32px;
            font-weight: var(--fw-regular);
            font-family: var(--ff-secondary);
            font-style: italic;

            span {
              font-style: normal;
            }
          }
        }

        .secondSection-heading-bottom-right {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: var(--size-500);

          p {
            font-size: 20px;
            font-weight: var(--fw-regular);
            max-width: 800px;
            line-height: 1.5;
          }
        }
      }
    }
  }

  .aof-container {
    width: 100%;
    margin-top: 0;
    position: relative;

    .aof-plant-video {
      position: absolute;
      bottom: -170px;
      right: 0;
      z-index: -1;
      width: 90%;

      @media (min-width: 768px) {
        width: 40%;
      }

      video {
        width: 100%;
        height: auto;
        // mix-blend-mode: hard-light;
      }
    }

    .aof-header {
      display: flex;
      flex-direction: column;
      width: 100%;

      hr {
        height: 0;
        border-top-width: 0;
      }

      .aof-hr {
        margin-top: 2px;
        height: 1px;
        width: 100%;
        background: var(--clr-neutral-900);
        border: none;
      }

      // .aof-line {
      //   content: '';
      //   width: 100%;
      //   height: 1px;
      //   background: var(--clr-neutral-900);
      // }

      .aof-heading {
        margin-top: var(--size-300);
        font-size: var(--fs-600);
        font-weight: var(--fw-semi-bold);
        padding-inline: 1rem;

        @media (min-width: 768px) {
          padding-inline: 0;
          font-size: var(--fs-680);
        }
      }
    }

    .services-aof-title {
      cursor: default;
      position: relative;
      z-index: 2;

      .services-aof-wrapper {
        margin-top: var(--size-700);
        display: flex;
        flex-direction: column;
        gap: var(--size-700);
        color: var(--clr-neutral-180);
        padding-inline: 1rem;

        @media (min-width: 768px) {
          margin-top: var(--size-1000);
          padding-inline: 0;
          gap: var(--size-300);
        }

        .services-aof-text {
          display: flex;
          flex-direction: column;
          position: relative;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          padding-bottom: 0.5rem;

          // &:nth-of-type(1) {
          //   border-top: 1px solid rgba(0, 0, 0, 0.25);
          // }

          p {
            color: hsl(0 0% 50%);
            text-transform: uppercase;
            font-weight: 700;
            font-size: 6vw;
            line-height: 1.25;
            margin: 0;
            position: relative;
            z-index: 2;
          }

          .second-p {
            color: var(--clr-neutral-180);
            margin-top: -1.25em;
            z-index: 1;

            @media (min-width: 768px) {
              margin-top: calc(-1.299em + 5px);
            }

            @media (min-width: 2500px) {
              margin-top: calc(-1.282em + 5px);
            }

            @media (min-width: 3000px) {
              margin-top: calc(-1.28em + 5px);
            }
          }
        }

        .services-aof-description {
          background-color: var(--clr-accent-500);
          display: none;
          justify-content: space-between;
          align-items: center;
          // padding-left: 10%;
          // padding-right: 10%;
          // margin-bottom: -15px;
          clip-path: inset(50% 0 50%);
          transition: clip-path 0.4s;
          margin-top: -1.25em;
          position: relative; // Ensure it has positioning context
          z-index: 3;

          @media (min-width: 1400px) {
            margin-top: calc(-9.5em + 5px);
            display: flex;
          }

          @media (min-width: 1600px) {
            margin-top: calc(-10.8em + 5px);
            display: flex;
          }

          @media (min-width: 1700px) {
            margin-top: calc(-11.1em + 5px);
            display: flex;
          }

          @media (min-width: 1900px) {
            margin-top: calc(-12em + 5px);
            display: flex;
          }

          @media (min-width: 2500px) {
            margin-top: calc(-15.5em + 5px);
            display: flex;
          }

          @media (min-width: 3400px) {
            margin-top: calc(-20.5em + 5px);
            display: flex;
          }

          p {
            &:nth-of-type(1) {
              color: #010101;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 6vw;
              line-height: 1.25;
              margin: 0;
              position: relative;
              z-index: 4;
            }
            &:nth-of-type(2) {
              color: hsl(0 0% 50%);
              width: 40%;
              font-size: 1vw;
              font-weight: 700;
              padding-right: 20px;
            }
          }
        }
      }
    }
  }

  .secondSection-services-container {
    .secondSection-services-wrapper {
      width: 100%;
      min-height: fit-content;
      // height: 3400px;
      padding-top: 5rem;
      padding-bottom: 10rem;
      // padding-bottom: 10rem;
      position: relative;
      background: var(--clr-neutral-150);
      border-radius: 1rem;
      z-index: 2;

      // &::before {
      //   position: absolute;
      //   height: 1px;
      //   content: '';
      //   background-color: var(--clr-neutral-900);
      //   opacity: 0.5;
      //   transform: scale3d(1, 1, 1);
      //   transform-origin: left center;
      //   width: 100%;
      //   transition: transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
      //   transition-delay: 0.3s;
      //   transform: scale3d(1, 1, 1);
      // }

      .secondSection-services-line {
        content: '';
        height: 1px;
        width: 100%;
        background: var(--clr-neutral-200);
        margin-block: var(--size-200);
      }

      .services-service {
        transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        grid-template-columns: 1fr;
        display: grid;
        gap: 30px;
        position: relative;
        padding: 15px;
        width: 100%;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          padding: 15px;
          background-color: hsl(0 0% 50%);
          border-radius: 20px;
          transform: scaleY(var(--after-scale, 0));
          opacity: var(--after-opacity, 0);
          transform-origin: bottom;
          transition: transform 0.25s ease-out, opacity 0.25s ease-out;
          z-index: 1;
        }

        @media (min-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr;
          display: grid;
        }

        .services-service-title-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: var(--size-500);
          z-index: 2;

          .services-service-title-location-wrapper {
            .services-service-title {
              font-size: var(--fs-700);
              font-weight: var(--fw-regular);
              color: var(--clr-neutral-300);
            }

            .services-service-title-location-icon-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: var(--size-200);

              .services-service-title-location-icon {
                font-size: var(--fs-650);
                color: var(--clr-neutral-300);
                margin-top: 3px;

                @media (min-width: 768px) {
                  margin-top: 0;
                }

                .service-icon {
                }
              }

              .services-service-title-location {
                font-size: var(--fs-600);
                font-weight: var(--fw-regular);
                color: var(--clr-neutral-300);
              }
            }

            .services-service-price {
              margin-top: var(--size-100);
              font-size: var(--fs-500);
              font-weight: var(--fw-semi-bold);
              color: var(--clr-neutral-300);
            }
          }

          .services-service-button {
            width: 70px;
            height: 35px;
            border-radius: 15px;
            position: relative; /* To position the arrow icons */
            overflow: hidden; /* To clip the hover arrow when it's moving in */
            background: var(--clr-accent-700);
          }

          /* Style for the default arrow icon */
          .services-service-button-inner-arrow-right {
            position: absolute;
            left: 50%;
            top: 60%;
            transform: translate(-50%, -50%);
            transition: transform 0.3s, opacity 0.3s;

            .service-arrow-icon {
              font-size: 30px;
              color: var(--clr-neutral-300);
            }
          }

          /* Style for the hover arrow icon */
          .services-service-button-inner-arrow-right-hover {
            position: absolute;
            left: 50%;
            top: 60%;
            transform: translate(-50%, -50%);
            opacity: 0; /* Start invisible */
            transition: transform 0.3s, opacity 0.3s;

            .service-arrow-icon {
              font-size: 30px;
              color: var(--clr-neutral-300);
            }
          }
        }

        .services-service-text {
          // min-height: 6.25rem;
          max-width: 100%;
          z-index: 2;

          @media (min-width: 768px) {
            max-width: 450px;
          }

          p {
            font-size: var(--fs-500);
            font-weight: var(--fw-regular);
            line-height: 1.5;
            color: var(--clr-neutral-200);

            @media (min-width: 768px) {
              font-size: var(--fs-600);
            }
          }
        }

        .services-service-image-wrapper {
          display: flex;
          justify-content: flex-start;

          @media (min-width: 768px) {
            justify-content: flex-end;
          }

          .services-service-image {
            overflow: hidden; // This is crucial for the zoom effect
            width: 100%; // Adjust based on your layout
            height: 230px; // Adjust based on your layout
            z-index: 2;
            border-radius: 20px;

            @media (min-width: 768px) {
              width: 450px;
            }

            img {
              width: 100%;
              height: 100%;
              transition: transform 0.35s ease-out; // Smooth transition for the zoom effect
              object-fit: cover; // This will ensure the image covers the area properly
            }
          }
        }
      }
    }
  }

  .accordion-container {
    position: relative;
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 7rem;
    padding-inline: 1rem;

    @media (min-width: 768px) {
      padding-inline: 0;
      margin-top: 7rem;
    }

    .accordion-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: var(--size-400);

      @media (min-width: 768px) {
        margin-bottom: var(--size-600);
      }

      .accordion-heading {
        margin-top: var(--size-300);
        font-size: 5rem;
        font-weight: var(--fw-semi-bold);

        @media (min-width: 768px) {
          font-size: 7rem;
        }
      }
    }

    .accordion {
      width: 100%;
      background: var(--clr-neutral-150);
      cursor: pointer;
      padding: 1rem 0;
      text-align: left;
      border: none;
      outline: none;
      border-bottom: 1px solid var(--clr-neutral-900);
      font-size: var(--fs-650);
      color: var(--clr-neutral-900);

      @media (min-width: 768px) {
        font-size: var(--fs-700);
      }
    }

    .accordion-panel {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: max-height 0.2s ease-out, opacity 0.3s;

      p {
        margin: 1rem 0;
        max-width: 700px;
        font-size: var(--fs-600);
        line-height: 1.5;
        color: var(--clr-neutral-300);
      }
    }

    .accordion-panel.active {
      opacity: 1;
    }
  }
}

// @keyframes jumbo {
//   from {
//     background-position: 50% 50%, 50% 50%;
//   }
//   to {
//     background-position: 350% 50%, 350% 50%;
//   }
// }

// .jumbo {
//   position: absolute;
//   top: 0;
//   left: 0;
//   // z-index: 999;
//   width: 100%;
//   height: 100vh;
//   opacity: 0.3;
//   background: white;
//   // z-index: 6;

//   --stripes: repeating-linear-gradient(
//     100deg,
//     #fff 0%,
//     #fff 7%,
//     transparent 10%,
//     transparent 12%,
//     #fff 16%
//   );
//   --stripesDark: repeating-linear-gradient(
//     100deg,
//     #000 0%,
//     #000 7%,
//     transparent 10%,
//     transparent 12%,
//     #000 16%
//   );
//   --rainbow: repeating-linear-gradient(
//     100deg,
//     #60a5fa 10%,
//     #e879f9 15%,
//     #60a5fa 20%,
//     #5eead4 25%,
//     #60a5fa 30%
//   );
//   background-image: var(--stripes), var(--rainbow);
//   background-size: 300%, 200%;
//   background-position: 50% 50%, 50% 50%;

//   filter: blur(10px) invert(100%);

//   mask-image: radial-gradient(ellipse at 100% 0%, black 40%, transparent 70%);

//   pointer-events: none;
// }

// .jumbo::after {
//   content: '';
//   position: absolute;
//   inset: 0;
//   background-image: var(--stripes), var(--rainbow);
//   background-size: 200%, 100%;
//   animation: jumbo 60s linear infinite;
//   background-attachment: fixed;
//   mix-blend-mode: difference;
// }

.main-tree-content {
  background: var(--clr-neutral-150);
  width: 100%;
  position: relative;
  overflow: hidden;
  scale: 0.95;
}

@supports (animation-timeline: scroll()) {
  .main-tree-content {
    view-timeline: --content;
    animation: grow linear both;
    animation-timeline: scroll();
    animation-range: 0 50vh;
  }
}

@keyframes grow {
  to {
    scale: 1;
  }
}

.main-tree-content svg {
  height: 100%;
  position: absolute;
  right: 0%;
  top: 0;
  overflow: visible !important;
  z-index: -1;
}

.main-tree-content svg path {
  stroke-width: clamp(2rem, 2vw, 4rem);
}

@media only screen and (orientation: portrait) {
  .main-tree-content svg {
    display: none;
  }
}

.main-tree-section {
  height: fit-content;

  @media (min-width: 768px) {
    height: 80vh;
  }

  &:nth-of-type(3) {
    margin-top: -6rem;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
  &:nth-of-type(4) {
    margin-top: -6rem;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
}

// section {
//   height: 80vh;
//   display: grid;
//   place-items: center;
//   position: sticky;
//   top: 0;
// }

// main {
//   width: 100%;
// }

.backers path {
  stroke: hsl(0 0% 50%);
}

.fillers path {
  stroke: hsl(0 100% 50% / 1);
}

.fillers path:nth-of-type(1) {
  --len: 8620;
  stroke: hsl(81, 99%, 69%);
}
.fillers path:nth-of-type(2) {
  --len: 7200;
  stroke: hsl(81, 100%, 69%);
}
.fillers path:nth-of-type(3) {
  --len: 7952;
  stroke: hsl(81, 99%, 70%);
}

.fillers path {
  stroke-dasharray: var(--len);
  stroke-dashoffset: var(--len);
}

@supports (animation-timeline: scroll()) {
  .fillers path {
    animation: fill linear both;
    animation-timeline: --content;
    animation-range: entry-crossing -38% exit 12%;
  }
}

@keyframes fill {
  to {
    stroke-dashoffset: 0;
  }
}

.main-tree-content .main-tree-section {
  display: grid;
  align-content: center;
  padding: 0 1rem;

  @media (min-width: 768px) {
    padding: 0 6rem;
  }
}

.main-tree-section h2 {
  font-size: clamp(2rem, 4vw + 1rem, 4rem);
  font-weight: 120;
  color: var(--clr-neutral-900);
  width: 10ch;
  white-space: nowrap;
}

.main-tree-section:nth-of-type(2) {
  margin-top: 10rem;
}

.main-tree-section:nth-of-type(3),
.main-tree-section:nth-of-type(4) {
  justify-items: end;
}

// a {
//   width: 56px;
//   display: inline-block;
//   aspect-ratio: 1;
//   position: fixed;
//   top: 1rem;
//   left: 1rem;
//   z-index: 10;
//   border-radius: 50%;
//   background: hsl(0 0% 100%);
//   display: grid;
//   place-items: center;
// }

// a img {
//   width: 80%;
// }

.main-tree-desc-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--size-500);
  position: relative;

  .main-tree-desc-bgImg {
    position: absolute;
    margin-top: 10rem;
    bottom: 0;
    right: 0;
    z-index: 1;

    @media (min-width: 768px) {
      position: absolute;
      bottom: -130px;
      left: 0;
      margin-top: 0;
    }

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center;
      // filter: grayscale(100);
      transition: 0.3s;

      @media (min-width: 768px) {
        width: 900px;
        height: auto;
      }

      // &:hover {
      //   filter: none;
      //   transition: 0.3s;
      // }
    }
  }

  .main-tree-desc-heading {
    font-size: var(--fs-700);
    font-weight: var(--fw-regular);
    font-family: var(--ff-secondary);
    font-style: italic;
    z-index: 2;
    mix-blend-mode: difference;
    color: var(--clr-neutral-100);
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 768px) {
      font-size: var(--fs-1000);
    }

    &:nth-child(2) {
      margin-top: -3rem;

      @media (min-width: 768px) {
        margin-top: 0;
      }
    }

    span {
      font-family: var(--ff-primary);
      font-size: var(--fs-650);
      font-style: normal;

      @media (min-width: 768px) {
        font-size: var(--fs-900);
      }
    }
  }

  .main-tree-desc {
    max-width: 100%;
    font-size: var(--fs-600);
    font-weight: var(--fw-regular);
    line-height: 1.5;
    z-index: 2;
    mix-blend-mode: difference;
    color: var(--clr-neutral-100);
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 768px) {
      max-width: 650px;
      font-size: var(--fs-650);
    }
  }
}

.main-tree-desc-wrapper-bottom-container {
  width: 100%;
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-end;
  // margin-right: 10rem !important;

  @media (min-width: 768px) {
    width: fit-content;
  }

  .main-tree-desc-wrapper-bottom {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: var(--size-500);
    position: relative;
    // position: absolute;
    margin-top: 13rem;
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .main-tree-desc-bgImg2 {
      position: absolute;
      // margin-top: 10rem;
      bottom: -20px;
      right: 20px;
      z-index: 1;

      @media (min-width: 768px) {
        position: absolute;
        bottom: -130px;
        right: -30px;
      }

      img {
        width: 100%;
        height: 290px;
        object-fit: cover;
        object-position: center;
        transition: 0.3s;

        @media (min-width: 768px) {
          width: 450px;
          height: auto;
        }

        // &:hover {
        //   filter: none;
        //   transition: 0.3s;
        // }
      }
    }

    .main-tree-desc-heading {
      font-size: var(--fs-700);
      font-weight: var(--fw-regular);
      font-family: var(--ff-secondary);
      font-style: italic;
      z-index: 2;
      mix-blend-mode: difference;
      color: var(--clr-neutral-100);
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (min-width: 768px) {
        font-size: var(--fs-900);
      }

      &:nth-child(2) {
        margin-top: -5rem;

        @media (min-width: 768px) {
          margin-top: 0;
        }
      }

      span {
        font-family: var(--ff-primary);
        font-size: var(--fs-650);
        font-style: normal;

        @media (min-width: 768px) {
          font-size: var(--fs-900);
        }
      }
    }

    .main-tree-desc {
      max-width: 100%;
      font-size: var(--fs-600);
      font-weight: var(--fw-regular);
      line-height: 1.5;
      z-index: 2;
      mix-blend-mode: difference;
      color: var(--clr-neutral-100);
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (min-width: 768px) {
        max-width: 650px;
        font-size: var(--fs-650);
      }
    }
  }
}

.quote-container {
  width: 100%;
  height: 600px;
  background: var(--clr-accent-1000);
  // border-radius: 1rem;
  padding: 30px;
  position: relative;

  .quote-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: var(--size-300);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .quote {
      h2 {
        color: var(--clr-neutral-900);
        font-size: var(--fs-650);
        font-weight: var(--fw-regular);

        @media (min-width: 768px) {
          font-size: var(--fs-700);
        }
      }
    }

    .quote-author {
      h3 {
        color: var(--clr-neutral-300);
        font-size: var(--fs-500);
        font-weight: var(--fw-regular);

        @media (min-width: 768px) {
          font-size: var(--fs-600);
        }
      }
    }
  }
}
