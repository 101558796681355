.app {
  height: 100%;
  width: 100%;
  position: relative;
}

// .app-background {
//   background-image: url('../assets/images/bg2.png');
//   background-size: cover;
//   background-repeat: repeat;
//   background-position: center;
//   height: 100vh;
// }
