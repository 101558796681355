.services-container {
  position: relative;
  min-height: fit-content;
  background: var(--clr-neutral-150);
  border-radius: 1rem;
  z-index: 3;
  padding-top: 5.5rem;
  margin-bottom: 0;
  margin-inline: auto;

  .services-header {
    display: flex;
    flex-direction: column;
    width: 100%;

    .services-line {
      content: '';
      width: 100%;
      height: 1px;
      background: var(--clr-neutral-900);
    }

    .services-heading {
      margin-top: var(--size-300);
      font-size: var(--fs-680);
      font-weight: var(--fw-semi-bold);
    }
  }

  .services-aof-wrapper {
    margin-top: var(--size-1000);
    display: flex;
    flex-direction: column;
    gap: var(--size-300);
    color: var(--clr-neutral-180);

    h2 {
      font-size: 7.8125vw;
      line-height: 13.02083vw;
    }
  }

  .secondSection-services-wrapper {
    width: 100%;
    min-height: 100%;
    height: 3400px;
    padding-top: 5rem;
    // padding-bottom: 5rem;
    position: relative;
    background: var(--clr-neutral-150);
    border-radius: 1rem;
    z-index: 2;

    h1 {
      font-size: var(--fs-1100);
      text-align: center;
    }

    .secondSection-services-container {
      width: 100%;
      background: var(--clr-neutral-150);
      border-radius: 1rem;

      .secondSection-services {
        width: 100%;
        min-height: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 36rem;

        .secondSection-service {
          width: 100%;
          margin-block: 10rem;
          display: flex;
          gap: 10rem;
          position: relative;

          &:nth-of-type(3) {
            padding-bottom: 20rem;
          }

          .secondSection-service-heading {
            flex: 1;
            display: flex;
            flex-direction: column;
            // gap: var(--size-600);
            max-width: 400px;

            h2 {
              font-weight: var(--fw-semi-bold);
              font-size: var(--size-650);
              color: var(--clr-neutral-200);
            }

            .secondSection-service-heading-icon-pricing-wrapper {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              .secondSection-service-heading-icon-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: var(--size-300);
                color: var(--clr-neutral-200);
                margin-top: var(--size-200);

                .service-icon {
                  font-size: 30px;
                }

                h3 {
                  font-size: var(--fs-600);
                }
              }

              .secondSection-service-pricing {
                margin-top: var(--size-200);

                h3 {
                  font-size: var(--fs-500);
                  font-weight: var(--fw-semi-bold);
                  color: var(--clr-neutral-300);
                }
              }
            }

            p {
              font-weight: var(--fw-regular);
              font-size: var(--size-400);
              color: var(--clr-neutral-200);
              line-height: 1.4;
              margin-top: var(--size-600);
            }
          }

          .secondSection-service-img {
            flex: 2;
            width: 100%;
            position: relative;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: auto;
              min-height: 600px;
              object-fit: cover;
              max-height: 100%;
              border-radius: 1.5rem;
            }
          }
        }
      }

      .secondSection-services-button {
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 3rem;
        left: 0;

        button {
          border: 1px solid var(--clr-neutral-200);
          background: transparent;
          width: 200px;
          padding: 10px;
          font-size: var(--fs-600);
          font-weight: var(--fw-regular);
          cursor: pointer;
        }
      }
    }
  }
}
