.inner {
  background: var(--clr-neutral-900);

  .page {
    background: var(--clr-neutral-150);
  }

  .slide {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--clr-neutral-150);
    width: 100vw;
    height: 100vh;
    z-index: 5;
  }
}
