.carousel-container {
  margin-top: 10rem;
  padding-top: 15rem;
  padding-bottom: 10rem;
  background: var(--clr-accent-700);
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    padding-bottom: 20rem;
  }

  .carousel-heading {
    cursor: default;

    h2 {
      font-size: 3.5rem;
      font-weight: var(--fw-bold);
      margin-left: 0;
      padding-inline: 1rem;
      max-width: 100%;
      line-height: 1;

      @media (min-width: 768px) {
        font-size: 7rem;
        margin-left: 4rem;
        padding-inline: 0;
        max-width: 600px;
      }
    }
  }

  .carousel-wrapper {
    margin-top: var(--size-600);
    padding-bottom: 10rem;
    display: flex;
    justify-content: center;
    position: relative;

    .carousel-details-wrapper {
      border: 1px solid var(--clr-neutral-900);
      border-radius: 1.5rem;
      padding: 30px;
      height: 254px;
      position: relative;

      h3 {
        font-size: var(--fs-650);
        line-height: 1.5;
      }

      h4 {
        font-size: var(--fs-680);
        font-weight: var(--fw-regular);
        font-family: var(--ff-secondary);
        font-style: italic;

        position: absolute;
        bottom: 30px;
        left: 30px;
      }
    }
  }

  .carousel-bottom-heading {
    position: absolute;
    bottom: -3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      bottom: -6rem;
      width: fit-content;
    }

    h1 {
      font-size: 3.5rem;
      font-weight: var(--fw-bold);

      @media (min-width: 768px) {
        font-size: 8rem;
      }

      span {
        font-family: var(--ff-secondary);
        font-weight: var(--fw-regular);
        font-style: italic;
      }
    }
  }
}

.carousel-overlay {
  z-index: 3;
  width: 30rem;
  height: 100%;
  background-image: linear-gradient(
    270deg,
    rgb(233, 233, 233, 1),
    rgba(231, 231, 231, 0)
  );
  display: block;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 45px;
}

.slick-slider {
  width: 100vw;
  max-width: 2450px;
  overflow: hidden;
}

.slick-list {
  width: 100%;
}

.slick-track {
  width: 100%;
}

.slick-slide {
  padding: 20px;
  // width: 300px !important;
  // height: 200px !important;
}

.slick-prev {
  // position: absolute !important;
  // left: 1430px !important;
  // top: -45px !important;
}

.slick-prev:before {
  font-size: 30px !important;
  color: var(--clr-neutral-400) !important;
}

.slick-next {
  // position: absolute !important;
  // right: 40px !important;
  // top: -44px !important;
}

.slick-next:before {
  font-size: 30px !important;
  color: var(--clr-neutral-400) !important;
}
